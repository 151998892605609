import { useSelector, useDispatch } from "react-redux";
import CartItemList from "../components/CartItemList";
import React, { useState } from "react";
import { cartActions } from "../features/cart/cartSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import "../css/cart.css";
import BasketModal from "../components/BasketModal";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartModal, setCartModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const cartProducts = useSelector((state) => state.cart.cartItems);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  // const [state, setState] = useState();

  const removeProducts = () => (
    dispatch(cartActions.deleteCart()), toast.success("Sepet Temizlendi!")
  );

  const isDisabled = false;

  const handleOpenModal = () => {
    setCartModal(true);
    handlePay();
  };

  const handleCloseModal = () => {
    setCartModal(false);
    setIsloading(false);
    removeProducts();
    navigate("/home");
  };

  const handlePay = async () => {
    setIsloading(true);
    try {
      setIsloading(true);
      let siparis = [];
      cartProducts.forEach((element) => {
        siparis.push({
          Name: element.title,
          IsGeneric: false,
          UnitCode: "KGM",
          TaxGroupCode: "KDV10",
          ItemQuantity: element.quantity,
          UnitPriceAmount: element.totalPrice,
          GrossPriceAmount: element.totalPrice,
          TotalPriceAmount: element.totalPrice,
          ReservedText: element.product_kod,
          PriceEffect: {
            Type: 1,
            Rate: 0.0,
            Amount: null,
          },
        });
      });

      const response = await fetch(
        "https://192.168.43.2:5000/api/values/FIS_NAKIT_TEK",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            result: siparis,
            PriceEffect: [
              {
                Type: 2,
                Rate: 0.0,
                Amount: null,
              },
            ],
            result1: [
              {
                Mediator: 2,
                Amount: totalAmount,
                CurrencyCode: "TRY",
                ExchangeRate: 1.0,
              },
            ],
          }),
        }
      );
      console.log("response", response);

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log("result", result);
      // setState(JSON.stringify(response))

      if (result.ResultCode === 0) {
        handleCloseModal();
      }
    } catch (err) {
      console.log(err);
      console.log("Bir hata oluştu!");
      handleCloseModal();
    }
  };

  return (
    <div
      className={
        "container-max py-8 pb-16 mb-10 items-center justify-items-center"
      }
    >
      <h1 className="text-2xl my-4 font-semibold text-center">Sepetim</h1>
      {totalQuantity > 0 && (
        <p className="text-center text-lg">
          Sepete toplam {totalQuantity} ürün eklediniz.
        </p>
      )}

      <div className="min-h-[60vh] pb-8 gap-8">
        <CartItemList />
      </div>
      {/* <div>
        {state}
      </div> */}

      <div className="mb-4 fixed z-50 bottom-0 left-0 right-0 flex flex-row items-center justify-around">
        <div className="flex flex-row items-center">
          <BsArrowLeft className="w-6 h-6 text-black mr-1" />
          <button className="text-black text-lg" onClick={() => navigate("/")}>
            Alışverişe Devam Et
          </button>
        </div>

        <div>
          {totalQuantity > 0 && (
            <div className="flex flex-row justify-between items-center pt-3 bg-black w-96 mx-auto rounded-xl text-white p-4 mb-4 mt-4">
              <div>
                <p className="fs-2 fw-2 ml-4">Toplam {totalAmount}₺</p>
              </div>

              <button
                onClick={handleOpenModal}
                className="fs-2 bg-white text-black w-4/6 rounded-full p-2 font-semibold"
              >
                Ödemeye Geç ({totalQuantity})
              </button>
            </div>
          )}
        </div>

        <div className="flex flex-row items-center ">
          <ImBin className="w-6 h-6 text-black mr-1" />
          <button
            disabled={isDisabled}
            className={`text-black text-lg ${
              isDisabled ? "line-through text-gray-400 cursor-not-allowed" : ""
            }`}
            onClick={removeProducts}
          >
            Sepeti Temizle
          </button>
        </div>
      </div>

      {cartModal && (
        <BasketModal
          isOpen={cartModal}
          setCartModal={setCartModal}
          isLoading={isLoading}
          setIsloading={setIsloading}
        />
      )}
    </div>
  );
};

export default Cart;
